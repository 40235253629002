import React from 'react';
import { createPortal } from 'react-dom';
import usePortal from '../lib/usePortal';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from 'prop-types';
import { usePortalContext } from '../lib/context/portalContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faMobile, faListCheck, faGear, faWifi } from '@fortawesome/pro-light-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

const Portal = ({ id, title, children }) => {
  const target = usePortal(id);
  const {togglePortal, isShowing, defaultKey} = usePortalContext();
  
  const nodes = Array.isArray(children) ? children.filter(child => {
    return child !== null && child !== undefined;
  }) : [children];
  
  let iconCfg = (type) => {

    const icons = {
      safecase: {
        icon: faMobile
      },
      user: {
        icon: faUser
      },
      policy: {
        icon: faListCheck
      },
      users: {
        icon: faUsers
      },
      hdm: {
        icon: faGear
      },
      wifi: {
        icon: faWifi
      },
      default: {
        icon: faListCheck
      }
    };

    return (
      <div className="fa-lg me-4">
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon icon={faCircle} size="2x" color="rgba(48, 112, 135, 0.6)" />
          <FontAwesomeIcon transform="shrink-1 right-6" icon={icons[type].icon} color="white" />
        </span>
      </div>
    );
  };

  return createPortal(
    <>
      <Offcanvas data-testid="offcanvas-portal" id="oc-root" show={isShowing} onHide={togglePortal} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title as="h2">{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Accordion alwaysOpen flush defaultActiveKey={[defaultKey.toString()]}>
            {nodes && nodes.map((child, i) => {
              return (
                <Accordion.Item eventKey={i.toString()} key={i}>
                  <Accordion.Header style={{display: child?.props?.title ? 'display':'none'}}>
                    {iconCfg(child?.props?.type ?? 'default')}
                    {child?.props?.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    {child}
                  </Accordion.Body>
                </Accordion.Item>
              )
            })}
          </Accordion>
        </Offcanvas.Body>
      </Offcanvas>
    </>,
    target,
  );
};

Portal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}


export default Portal;
